import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDebounce } from '@lib/hooks';
import { graphql } from '@lib/api';
import { gql } from '@apollo/client';
import Search from '@lib/models/search';
import { CITY_QUERY, COUNTY_QUERY, SCHOOL_QUERY, ZIP_QUERY } from '@lib/search-query';
import { convertFromReduxParams, pushStateToQuery } from '@lib/query-utils';

// ---------------------------------------------------------

import TabSlider from '@components/tab-slider';
import MultiSelect from '@components/select/react-multi-select';
import RadioButton from '@components/radio-button';
import Button from '@components/button';

const search = new Search();

// ---------------------------------------------------------

import { button_container } from '../styles.module.scss';

// ---------------------------------------------------------

const mapArea = {
  counties: 'county',
  places: 'city',
  schoolDistricts: 'schoolDistrict',
  zip: 'zipCode'
};

const mapAreaReadable = {
  counties: 'county',
  places: 'city',
  schoolDistricts: 'school district',
  zip: 'zip'
};
const mapAreaPlaceholderText = {
  counties: 'Begin typing county',
  places: 'Begin typing city or suburb',
  schoolDistricts: 'Begin typing school district',
  zip: 'Begin typing zip code'
};

// ---------------------------------------------------------

const StepOne = (props) => {
  let { setCurrentStep, modalFilters, setModalFilters, setModalIsOpen, quicklink, isNewSearch } =
    props;

  const router = useRouter();

  const defaultArea = quicklink === 'School District' ? 'schoolDistricts' : 'places';
  const [selectedState, setSelectedState] = useState('OH');
  const [selectedAreaType, setSelectedAreaType] = useState(
    quicklink === 'School District' ? 'schoolDistricts' : 'places'
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const debouncedAreaType = useDebounce(selectedAreaType, 300);
  const [count, setCount] = useState(0);

  const reduxParams = useSelector((state) => state.searchFilters);

  // ---------------------------------------------------------
  const getDropdownQuery = (areaType) => {
    if (areaType === 'places') {
      return CITY_QUERY;
    } else if (areaType === 'counties') {
      return COUNTY_QUERY;
    } else if (areaType === 'schoolDistricts') {
      return SCHOOL_QUERY;
    } else if (areaType === 'zip') {
      return ZIP_QUERY;
    }
  };

  useEffect(() => {
    setLoading(true);

    if (debouncedAreaType) {
      const fetchData = async () => {
        let queryData;
        try {
          queryData = await graphql(
            gql`
              ${getDropdownQuery(selectedAreaType)}
            `,
            {
              state: selectedState
            }
          );
        } catch (err) {
          setError(true);
        }

        setData(queryData);
        setLoading(false);
      };

      fetchData();
    }
  }, [debouncedAreaType, selectedState]);

  useEffect(() => {
    if (!isNewSearch) {
      fetchCount();
    }
  }, [modalFilters]);

  const fetchCount = async () => {
    const { paramsForGraphQL } = await convertFromReduxParams(
      {
        ...reduxParams,
        locations: modalFilters.locations
      },
      router
    );
    const data = await search.perform({
      params: paramsForGraphQL,
      queryType: 'advancedSearch'
    });
    setCount(data?.listingSearch?.totalCount ?? 0);
  };

  const handleLocationsChange = (newLocations) => {
    setModalFilters({ ...modalFilters, locations: newLocations });
  };

  const options =
    !loading && !error && data && data[Object.keys(data)[0]].items.length > 0
      ? data[Object.keys(data)[0]].items.map((e) => {
          return {
            label: `${e[mapArea[selectedAreaType]]}, ${selectedState}`,
            value: {
              areaType: selectedAreaType,
              location: e[mapArea[selectedAreaType]],
              state: selectedState
            }
          };
        })
      : [];

  const getOptions = () => {
    if (loading) {
      return ['loading'];
    } else if (error) {
      console.log(error);
      return ['error'];
    } else {
      return [...new Set(options)]; // remove dupes from options
    }
  };

  const resultsPageClick = () => {
    pushStateToQuery(router, { ...reduxParams, locations: modalFilters.locations });
    setModalIsOpen(false);
  };

  const getNextButton = () => {
    if (isNewSearch) {
      return <Button onClick={() => setCurrentStep(2)} label="Next Step"></Button>;
    } else {
      return (
        <Button
          label={`View ${count ? count : '0'} properties`}
          onClick={resultsPageClick}
        ></Button>
      );
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <>
          <div style={{ marginBottom: '30px', marginTop: '20px' }}>
            <RadioButton
              items={[
                {
                  icon: 'ohio',
                  label: 'Ohio',
                  value: 'OH'
                },
                {
                  icon: 'kentucky',
                  label: 'Kentucky',
                  value: 'KY'
                },
                {
                  icon: 'indiana',
                  label: 'Indiana',
                  value: 'IN'
                }
              ]}
              selection={selectedState}
              theme="with-icon"
              groupName="state"
              label="Select State"
              onChange={setSelectedState}
            ></RadioButton>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <TabSlider
              items={[
                { label: 'Cities', value: 'places' },
                { label: 'Counties', value: 'counties' },
                { label: 'Schools', value: 'schoolDistricts' },
                { label: 'Zip', value: 'zip' }
              ]}
              label="Select Location Type"
              defaultSelection={selectedAreaType || defaultArea}
              setSelectedTabCallback={setSelectedAreaType}
            ></TabSlider>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <MultiSelect
              label={
                mapAreaReadable[selectedAreaType] === 'school district'
                  ? 'Add School District(s)'
                  : 'Add Location(s)'
              }
              placeholder={mapAreaPlaceholderText[selectedAreaType]}
              onChange={handleLocationsChange}
              selectedItems={modalFilters?.locations || []}
              selectOptions={getOptions()}
              isLoading={loading}
            ></MultiSelect>
          </div>
        </>
        {modalFilters?.locations?.length > 0 && (
          <div style={{ marginBottom: '30px' }} className={button_container}>
            {getNextButton()}
          </div>
        )}
      </div>
    </>
  );
};

StepOne.propTypes = {
  /**
   * Specifies whether the modal is for a new or existing search
   */
  isNewSearch: PropTypes.bool,

  /**
   * modalFilters that keep state local so that redux doesn't get updated before apply
   */
  modalFilters: PropTypes.shape({
    locations: PropTypes.array,
    maxPrice: PropTypes.number,
    minPrice: PropTypes.number,
    propertySubSelectIds: PropTypes.array,
    propertyTypeId: PropTypes.string
  }),

  /**
   * Specifies whether the modal is currently opened
   */
  modalIsOpen: PropTypes.bool,

  /**
   * Specifies which quick link button opened the advanced search modal
   */
  quicklink: PropTypes.string,

  /**
   * Updates which step of the advanced search is currently opened
   */
  setCurrentStep: PropTypes.func,

  /**
   *  Updates the current local modal selections
   */
  setModalFilters: PropTypes.func,

  /**
   * Sets whether the modal is open or closed
   */
  setModalIsOpen: PropTypes.func
};

StepOne.defaultProps = {
  isNewSearch: false
};

export default StepOne;
