import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

import Icon from '@components/icon';

// ---------------------------------------------------------

import {
  has_icon,
  tab_label,
  tab,
  tabs,
  tab_container,
  label_and_icon,
  theme_pill,
  theme_default,
  theme_unified
} from './styles.module.scss';

// ---------------------------------------------------------

const themeOptions = {
  default: theme_default,
  pill: theme_pill,
  unified: theme_unified
};

// ---------------------------------------------------------

const TabSlider = (props) => {
  const router = useRouter();
  const { items, setSelectedTabCallback, defaultSelection, label, hideLabels, theme } = props;

  const manualActive = items.some((item) => item && item.isActive);

  const checkActiveState = () => {
    let label;

    items.forEach((item) => {
      if (item && item.url == router?.pathname) {
        label = item.label;
      }
    });

    return label;
  };

  const [activeTab, setActiveTab] = useState(
    checkActiveState() || defaultSelection || items[0]?.value || items[0]?.label
  );

  useEffect(() => {
    if (!router.isReady) return;
    if (defaultSelection) {
      setActiveTab(defaultSelection);
    }
  }, [router.isReady]);

  const handleClick = (e, url) => {
    if (url) {
      return router.push(url);
    }

    const target = e.currentTarget.id;
    setActiveTab(target);
    setSelectedTabCallback && setSelectedTabCallback(target);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setActiveTab(e.target.id);
      setSelectedTabCallback && setSelectedTabCallback(e.target.ariaLabel);
    }
  };

  return (
    <div className={classNames(tab_container, themeOptions[theme])}>
      <span>{label}</span>
      <div className={tabs}>
        {items.map((item, idx) => {
          if (item === null) {
            return null; // Skip rendering null items
          }

          const tabWidth = 100 / items.length;

          // Overrides the active state if any of the input items have item.isActive on them
          const itemIsActive =
            item.isActive ||
            (!manualActive && (item.value === activeTab || item.label === activeTab));

          return (
            <div
              role="radio"
              tabIndex={0}
              id={item.value || item.label}
              aria-checked={itemIsActive}
              aria-label={item.icon && item.label}
              onKeyDown={handleKeyDown}
              className={classNames(tab, {
                [has_icon]: item.icon,
                [label_and_icon]: !hideLabels && item.icon
              })}
              style={theme === 'pill' ? { width: `${tabWidth}%` } : {}}
              onClick={(e) => handleClick(e, item.url)}
              key={idx}
            >
              {item.icon && <Icon type={item.icon.type} name={item.icon.name} />}
              {!hideLabels && <div className={tab_label}>{item.label}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TabSlider.propTypes = {
  /**
   * Specifies the label of the selection that should be selected as the default
   * Defaults to the first item if none selected
   */
  defaultSelection: PropTypes.string,

  /**
   * Removes labels inside of the tabs
   */
  hideLabels: PropTypes.bool,

  /**
   * An array of items to be displayed as tabs
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string
      }),
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),

  /**
   * An optional label to appear above the tabs selector
   */
  label: PropTypes.string,

  /**
   * An optional callback to update state of a parent component
   */
  setSelectedTabCallback: PropTypes.func,

  /**
   * Specifies theme
   */
  theme: PropTypes.string
};

TabSlider.defaultProps = {
  theme: 'pill'
};

export default TabSlider;
