import PropTypes from 'prop-types';
import { deepClone } from '@lib/utils';

// ---------------------------------------------------------

import Select from '@components/select';

// ---------------------------------------------------------

import { minmax_select_container, header } from '../styles.module.scss';

const MinMaxSelect = (props) => {
  const { anyLabel, options, defaultMin, defaultMax, min, max, onChangeMinMax, label } = props;

  const any = (range, value) => {
    range.unshift({ label: anyLabel, value: value });
    return range;
  };

  const minR = () => any(deepClone(options), defaultMin);
  const maxR = () => any(deepClone(options), defaultMax);

  const onChangeMin = (value) => {
    const minVal = parseInt(value);
    if (minVal < max) {
      onChangeMinMax({ max, min: minVal });
    }
  };

  const onChangeMax = (value) => {
    const maxVal = parseInt(value);
    if (maxVal > min) {
      onChangeMinMax({ max: maxVal, min });
    }
  };

  return (
    <>
      {label && (
        <header className={header}>
          <span>{label}</span>
        </header>
      )}
      <div className={minmax_select_container}>
        <Select label="Minimum" onChange={onChangeMin} options={minR()} selected={min} />
        <Select label="Maximum" onChange={onChangeMax} options={maxR()} selected={max} />
      </div>
    </>
  );
};

MinMaxSelect.propTypes = {
  /**
   * Specifies the label for the any option in the select dropdown
   */
  anyLabel: PropTypes.string.isRequired,

  /**
   * Default maximum value
   */
  defaultMax: PropTypes.number,

  /**
   * Default minimum value
   */
  defaultMin: PropTypes.number,

  /**
   * An optional label to appear above the component
   */
  label: PropTypes.string,

  /**
   * Specifies the current max value
   */
  max: PropTypes.number,

  /**
   * Specifies the current min value
   */
  min: PropTypes.number,

  /**
   * onChange function to trigger when the value in the min/max select is changed
   */
  onChangeMinMax: PropTypes.func.isRequired,

  /**
   * Specifies the select options
   */
  options: PropTypes.array
};

MinMaxSelect.defaultProps = {
  max: 100,
  min: 0
};

export default MinMaxSelect;
