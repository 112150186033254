export default {
  iconAndLabel: {
    items: [
      { icon: { name: 'photos', type: 'filters' }, label: 'Tab One' },
      { icon: { name: 'like', type: 'filters' }, label: 'Tab Two' }
    ],
    label: 'Select Options'
  },
  iconTabs: {
    hideLabels: true,
    items: [
      { icon: { name: 'photos', type: 'filters' }, label: 'Tab One' },
      { icon: { name: 'location', type: 'filters' }, label: 'Tab Two' },
      { icon: { name: 'neighborhood', type: 'filters' }, label: 'Tab Three' },
      { icon: { name: 'floorplan', type: 'filters' }, label: 'Tab Four' }
    ],
    label: 'Select Options with an Icon'
  },
  props: {
    items: [{ label: 'Tab One' }, { label: 'Tab Two' }],
    label: 'Select Options'
  },
  test: {
    items: [{ label: 'Buy' }, { label: 'Home Value' }],
    theme: 'default'
  }
};
