import { gql } from '@apollo/client';

export const CITY_QUERY = gql`
  query GetCitiesList($state: String!) {
    citySelectList(where: { state: { eq: $state } }, order: { city: ASC }) {
      totalCount
      items {
        city
      }
    }
  }
`;

export const COUNTY_QUERY = gql`
  query GetCountiesList($state: String!) {
    countySelectList(where: { state: { eq: $state } }, order: { county: ASC }) {
      totalCount
      items {
        county
      }
    }
  }
`;

export const SCHOOL_QUERY = gql`
  query GetSchoolDistrictList($state: String!) {
    schoolDistrictSelectList(where: { state: { eq: $state } }, order: { schoolDistrict: ASC }) {
      totalCount
      items {
        schoolDistrict
      }
    }
  }
`;

export const ZIP_QUERY = gql`
  query GetZipCodeList($state: String!) {
    zipCodeSelectList(where: { state: { eq: $state } }, order: { zipCode: ASC }) {
      totalCount
      items {
        zipCode
      }
    }
  }
`;

export const OFFICE_QUERY = gql`
  query Office($states: [String]) {
    officeSearch(where: { state: { in: $states } }) {
      items {
        address
        city
        state
        zipCode
        name
        officeId
        photoUrl
        agents(where: { isPrimaryManager: { eq: true } }) {
          agent {
            email
          }
        }
        phone
      }
    }
  }
`;
